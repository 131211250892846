import React, { useState } from 'react';

import { clsx } from '@digital-spiders/misc-utils';
import * as styles from './Slider.module.scss';

export type SliderProps = {
  className?: string;
  animationDirection?: 'left' | 'right';
  children: React.ReactNode;
  animationDuration: number;
  pauseOnHover?: boolean;
  noAnimation?: boolean;
  elementsClassName?: string;
};

const Slider = ({
  children,
  pauseOnHover,
  animationDirection,
  animationDuration,
  noAnimation,
  className,
  elementsClassName,
}: SliderProps): React.ReactElement => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className={clsx(styles.elementsWrapper, className)}>
      <div
        className={clsx(
          styles.elementsContainer,
          animationDirection === 'left' && styles.animationToLeftSide,
          noAnimation && styles.stopAnimation,
          elementsClassName,
        )}
        style={{
          animationDuration: `${animationDuration}s`,
          animationPlayState: pauseOnHover && isHovered ? 'paused' : 'running',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {children}
        <div className={styles.elementsContainerCopy}>{children}</div>
      </div>
    </div>
  );
};

export default Slider;
